<template>
  <div class="success">
    <Icon name="check-circle" />
    <BaseSocialLinks
      :share-title="share.title"
      :share-link="share.link"
      class="social"
    >
      <template slot="title">
        {{ $t('registration.share') }}
      </template>
    </BaseSocialLinks>

    <div class="form-footer">
      <pvp-btn @click="$emit('send', 'CLOSE')">
        {{ $t('global.done') }}
      </pvp-btn>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';

export default {
  name: 'JobfairCreationSuccess',
  components: {
    Icon,
    BaseSocialLinks,
  },
  props: {
    teamName: {
      type: String,
      default: '',
    },
    gameId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    gameTitle() {
      return this.getGame(this.gameId)?.name;
    },
    share() {
      return {
        title: this.$t(
          `jobfair.share_${this.teamName ? 'team' : 'player'}`,
          {
            team: this.teamName,
            game: this.gameTitle,
            host: window.location.host.toLocaleUpperCase(),
          },
        ),
        link: `${window.location.origin}/player/${this.getCurrentProfileHash}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  text-align: center;
}

.icon {
  width: 30px;
  height: 30px;
  color: $dark-lime-green;
}

.social {
  margin-top: 28px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
