import { createMachine, assign } from 'xstate';

export const jobfairCardModal = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QCsD2AjAZgQwJYCcBhbfCAWVQmwBsA6AY2tVkgGIB5ABQFEA5AbQAMAXUSgADs1wAXXKgB2YkAA9EARgCsAJi20AHHo0AWLUYDsggMyDjWgDQgAnoiN7LtNWrNa1egGyWalpmbgC+oQ5oWHhEJOSUNAxMLBAcPAJqokggkrAycorZqghqgn5+tEaWln6CXtbVVg7OCFqCulrW5mrlWgCclqZa4ZEYOATEpBRUdFDYALZgAMpg1GD00qy83AAaACpCWRJSsgpKxZoGHoN6vm1tIUbN6mVmtH56ff3aakZGfpoRiAouNYlMErMFstVutNoQADLsJbcQ5KXL5M5FdQAvq0SwadofEwfDR6Z4IExvMwDfx9f4A4YRYFjGKTeIzBhxPaOcTQtYbLa7A4iNEnArnRAAozvIx9MwaPwDQSy8meKqVPzU7RmLzGPoaIEg1lxaaJehcnl82GC-b8TKivKnQqgYoaDRqWjBMyNDRmf6GexORB6Gy0eWBIwKkyBOWGlkTE0Qzmkbm8lb8zYAIQAgoQANKo7Lop0SkplCpVGp1b1WBrkukVOmRwTy3yuTpx6IJ8Ec80py3p60IpEokVFsWYl0vcqVaq1eq1ppBhCfCt6wZBPzaSx9TugtmmujSMALQj4E9Om3Co45CfOlSIHx6T3KoxeQQDPqvSzkzq42Vvi2+pqDUXh7saPaJMep7ntgl45vmhbHI64pYq0vgvn876ft+qrtLo3iBFu7SaBYljgd27JQSe8xnheBSsMOyJIbeKGTg+6HPm0WEWDhmo-sutyVIYHw7uU+qDAaTJGpRh7JhAKzSLI8hQLArAIQWY7IRi96uu6npmN61i+v62j1nUtBym4hmSRofRqBRYJUXQfYKWASm4CpakaXaN7FqhU4IG6Hpej6fokoGLQ1M+OofpoxE6P8jkHkmrmKcpqlXix-nsXpIU2cZ4UBuS8oaJ6AS+u0BKgeR0nxk5clpe5GVqdstr2uObG6YgwUGUZNhFWZy5vribTKvZypPrVoxdg1qVxOlnmZQAItw8LcHso5+Xepa9aFhWmZFiAWB6fwBIItx6J0LbJYmvYLc1S1qUxW0Ojpu36ftA2HeS7plSYvo6N4xjGNNzKzSlHIQDCx6EAomAEPMcEMT5Wmse9aHeLiOquAqOj4m4fjksRlkKt6wRfpYrh+LdkF0NDayw-DiPIwojGIsxaM5d1ZYzpW841o0AktAEFTvu6k2-NUUkzfud1mrBTpLAArvQ9BgGwL3ZTtaGlHzc7Vg0dbLuTeL6n6PjKhYrjhEy8iUHASgyXNMxvSWaEALRE8uHtlX0-sB4Hgdg87kNmskkBuwFHEgT4lmaJYtzyljZjkjuHQJ0BfSKkYF0y+Dct07QcyLIOGxR7l6h+qdGiBP0Oj+-iwsuFc2cAto-zWCEIf1WHLkWmmMLl51GOBf4IV9Hofpylo-EfOZbxXd6nikTuDl1RD8tHjRdGs-e3OliY9bSrXWjGOY1h6CYei0858mLV5Fc840eItl6q6T4nv47pZdIgcYHwp6ylvnJBm7kwBw3kAjfASN3baTgRxK+MUBqWD9NLM+fRiZ2VoLXNwF0BhaCvu6EBqVFYFBVmrDWEAn6livu4N0tR2iXVQcqEqgxyp+mpF4K+jxbahCAA */
  id: 'jobfairCardModal',
  initial: 'closed',
  states: {
    closed: {
      entry: ['closeModal'],
      exit: ['openModal', 'setGameId', 'setCardId'],
      on: {
        OPEN: [{
          target: 'gameSelect',
          cond: 'isCreate',
        }, {
          target: 'cardSettings',
          cond: 'isEdit',
          actions: ['setCardType'],
        }],
      },
    },

    gameSelect: {
      on: {
        NEXT: {
          target: 'cardTypeSelect',
          actions: ['setGameId'],
        },
        CLOSE: 'closed',
      },
    },

    cardTypeSelect: {
      on: {
        NEXT: [{
          target: 'teamCreation',
          actions: ['setCardType'],
          cond: 'needTeam',
        }, {
          target: 'cardSettings',
          actions: ['setCardType'],
          cond: 'noTeamNeeded',
        }],
        BACK: 'gameSelect',
        CLOSE: 'closed',
      },
    },

    teamCreation: {
      on: {
        NEXT: 'cardSettings',
        BACK: 'cardTypeSelect',
        CLOSE: 'closed',
      },
    },

    cardSettings: {
      on: {
        BACK: [{
          target: 'cardTypeSelect',
          cond: 'isCreate',
        }, {
          target: 'closed',
          cond: 'isEdit',
        }],
        NEXT: [{
          target: 'creationSucceed',
          cond: 'isCreate',
        }, {
          target: 'closed',
          cond: 'isEdit',
          actions: ['updateList'],
        }],
        DELETE: {
          target: 'deleteConfirmation',
          cond: 'isEdit',
        },
        CLOSE: 'closed',
      },
    },

    deleteConfirmation: {
      on: {
        BACK: 'cardSettings',
        CLOSE: {
          target: 'closed',
          actions: ['updateList'],
        },
      },
    },

    creationSucceed: {
      entry: ['setTeamName'],
      on: {
        CLOSE: {
          target: 'closed',
          actions: ['updateList'],
        },
      },
    },
  },

  context: {
    cardId: null,
    isOpen: false,
    gameId: null,
    cardType: '',
    teamName: '',
    needUpdate: false,
  },
}, {
  guards: {
    isEdit: (ctx, event) => Boolean(ctx.cardId || event.cardId),
    isCreate: (ctx, event) => Boolean(ctx.cardId || event.cardId) === false,
    needTeam: (ctx, { hasTeam, cardType }) => cardType === 'vacancy' && hasTeam === false,
    noTeamNeeded: (ctx, { cardType, hasTeam }) => cardType === 'resume' || hasTeam === true,
  },
  actions: {
    closeModal: assign({
      isOpen: false,
      gameId: null,
      cardId: null,
      cardType: '',
      teamName: '',
    }),
    openModal: assign({
      isOpen: true,
      needUpdate: false,
    }),
    updateList: assign({ needUpdate: true }),
    setCardId: assign({ cardId: (ctx, { cardId }) => cardId }),
    setGameId: assign({ gameId: (ctx, { gameId }) => gameId || 1 }),
    setCardType: assign({ cardType: (ctx, { cardType }) => cardType }),
    setTeamName: assign({ teamName: (ctx, { teamName }) => teamName }),
  },
});

