<template>
  <div class="profile">
    <div class="profile-header">
      <div class="profile-box inline">
        <BaseLink
          v-if="isTeams"
          :to="{ name: 'team', params: { hash: profile.teamHash } }"
          @click-native="profileClickHandler"
        >
          <team-avatar
            :src="profile.teamAvatar"
            :class="['profile-avatar', { current: isCurrent }]"
          />
        </BaseLink>
        <BaseLink
          v-else
          :to="{
            name: 'profile',
            params: { hash: profile.playerHash },
          }"
          @click-native="profileClickHandler"
        >
          <user-avatar
            :src="profile.avatar"
            :class="['profile-avatar', { current: isCurrent }]"
          />
        </BaseLink>
        <div class="profile-nick-box">
          <BaseLink
            v-if="isTeams"
            class="profile-nick"
            :to="{ name: 'team', params: { hash: profile.teamHash } }"
          >
            {{ profile.teamName }}
          </BaseLink>
          <BaseLink
            class="profile-nick"
            :to="{
              name: 'profile',
              params: { hash: profile.playerHash },
            }"
          >
            {{ profile.nick }}
          </BaseLink>
          <div class="profile-game">
            <game-tag
              :id="profile.gameId"
              :display-name="false"
              class="big"
            />
            <div class="game-nick">
              {{ profile.gameNick }}
            </div>
          </div>
        </div>
      </div>

      <div class="profile-box target">
        <div class="profile-info">
          <div class="info-title">{{ $t('players.role_few') }}:</div>
          <div class="info-text">
            <pvp-tooltip
              v-for="(role, key) in profile.roles"
              :key="key"
              class="button"
            >
              <template v-if="role">
                <Icon :name="role.value" group="jobfair" inline />
                <template slot="tooltip">{{ role.label }}</template>
              </template>
            </pvp-tooltip>
          </div>
        </div>
        <div class="profile-info">
          <div class="info-title">{{ $t('global.target') }}:</div>
          <div class="info-text">{{ profile.target }}</div>
        </div>
      </div>

      <div class="profile-box age">
        <div class="profile-info">
          <div class="info-title">{{ $t('global.age') }}:</div>
          <div class="info-text">
            <template v-if="isTeams"
              >{{ profile.ageMin }} - {{ profile.ageMax }}</template
            >
            <template v-esle>{{ profile.age }}</template>
          </div>
        </div>
        <div class="profile-info">
          <div class="info-title">{{ $t('global.contacts') }}:</div>
          <div class="info-text">
            <pvp-btn
              v-for="(contact, key) in profile.contacts"
              :key="key"
              variant="clear"
              class="contact"
              @click="copyLink(contact.contactValue)"
            >
              <pvp-tooltip>
                <Icon :name="contact.icon" inline />
                <template slot="tooltip">
                  {{
                    isCopied
                      ? $t('global.copied')
                      : contact.contactValue
                  }}
                </template>
              </pvp-tooltip>
            </pvp-btn>
          </div>
        </div>
      </div>

      <div class="profile-box border">
        <pvp-tooltip v-if="isCurrent">
          <Button
            v-if="profile.abilities.canPushUp"
            tag="button"
            size="regular"
            type="secondary"
            :loading="false"
            :text="$t('jobfair.button_up')"
            @click.native="pushUp(profile.id)"
          >
          </Button>

          <time-left
            v-else
            :is-countdown="true"
            :to="profile.pushUpTimeout"
          />

          <template slot="tooltip">{{
            $t('jobfair.button_upInfo')
          }}</template>
        </pvp-tooltip>
        <template v-else>
          <profile-match-progress
            v-if="profile.matchesStatistic"
            :stats="profile.matchesStatistic"
          />
          <div v-else class="nomatches">
            <Icon name="pvp-swords" />
            <template v-if="isTeams">
              {{ $t('teams.no-tournaments') }}
            </template>
            <template v-else>
              {{ $t('players.no-tournaments') }}
            </template>
          </div>
        </template>
      </div>

      <pvp-btn
        class="profile-more-btn"
        variant="clear"
        icon-right="arrow-down"
        @click="toggleMore"
      >
        <span class="expand">{{ $t('global.expand') }}</span>
        <span class="collapse">{{ $t('global.collapse') }}</span>
      </pvp-btn>
    </div>

    <div class="profile-more">
      <div class="info-title">{{ $t('global.additionally') }}:</div>
      <div class="info-text">
        <template v-if="profile.description">{{
          profile.description
        }}</template>
        <i18n
          v-else
          path="jobfair.notfound_description"
          class="notfound"
        >
          <b slot="nick">{{ profile.nick || profile.teamName }}</b>
        </i18n>
      </div>
      <Button
        v-if="profile.abilities.canUpdate"
        class="link"
        tag="button"
        size="regular"
        type="secondary"
        :loading="false"
        :text="$t('global.edit')"
        @click.native="editProfile"
      >
        <template #slotBefore>
          <Icon name="settings" inline />
        </template>
      </Button>
      <BaseLink
        v-else-if="isTeams"
        :to="{ name: 'team', params: { hash: profile.teamHash } }"
        icon-right="arrow-right"
        class="link"
        @click-native="profileClickHandler"
        >{{ $t('profile.goto') }}
      </BaseLink>
      <BaseLink
        v-else
        :to="{
          name: 'profile',
          params: { hash: profile.playerHash },
        }"
        icon-right="arrow-right"
        class="link"
        @click-native="profileClickHandler"
        >{{ $t('profile.goto') }}
      </BaseLink>
    </div>
  </div>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import { copyToClipboard } from '@utils/copyToClipboard';

import ProfileMatchProgress from '@components/ProfileComponents/ProfileMatchProgress.vue';
import Button from '@components/v2/ui/Button.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'JobfairBox',
  components: {
    ProfileMatchProgress,
    Button,
    Icon,
    BaseLink,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isCopied: false,
  }),
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    isTeams() {
      return this.type === 'vacancy';
    },

    isCurrent() {
      return this.profile.playerHash === this.getCurrentProfileHash;
    },
  },
  methods: {
    profileClickHandler() {
      // ======================================================
      // METRICS Турниры. Переход на профиль на напарниках
      const tmrParams = {
        category: 'pvp',
        action: 'teamfinder_profile_click',
        label: this.isTeams ? 'Команда' : 'Игрок',
        url: window.location.href,
      };
      pushEvents('pvp_teamfinder_profile_click', tmrParams);
      // ======================================================
    },
    toggleMore(e) {
      const el = e.target.closest('.button');
      el.classList.toggle('up');
      el.parentNode.nextElementSibling.classList.toggle('open');
    },

    copyLink(link) {
      copyToClipboard(link);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 500);
    },

    pushUp(id) {
      api
        .post('/declaration/pushup', {
          id,
          type: this.type,
        })
        .then(() => this.$emit('pushed'));
    },

    editProfile() {
      this.$emit('edit', true, {
        cardId: this.profile.id,
        gameId: this.profile.gameId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  // border-top: 1px solid $popover-bg;
  // border-bottom: 1px solid $popover-bg;
  // background-color: $dark-background;

  // & + & {
  //   margin-top: 10px;
  // }

  &:nth-child(odd) {
    background: #100f0f;
  }
  &:nth-child(even) {
    background: #161516;
  }

  &-header {
    @include min-desktop() {
      display: grid;
      grid-template-columns: 0.9fr 0.9fr 1fr 0.8fr 24px;
      padding-left: 10px;
    }
    @include max-desktop() {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-box {
    flex-shrink: 0;
    @include min-desktop() {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    &.inline {
      display: flex;
      overflow: hidden;
      order: 1;
      width: 100%;
      @include max-desktop() {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }

    &.target {
      order: 3;
      @include min-desktop() {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-bottom: -8px;
      }
    }

    &.age {
      order: 2;
      @include min-desktop() {
        margin-bottom: -8px;
      }
    }

    &.border {
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include min-desktop() {
        position: relative;
        flex-shrink: 0;
        order: 4;

        &:before {
          content: '';
          height: 50%;
          width: 1px;
          position: absolute;
          top: 25%;
          left: 0;
          background-color: $popover-bg;
        }
      }
      @include max-desktop() {
        margin: 20px auto 0;
      }

      .pushup {
        min-width: 158px;
      }
    }
  }

  &-info {
    display: flex;
    margin-bottom: 8px;
  }

  &-nick {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    @include min-desktop() {
      font-size: 12px;
    }
    @include max-desktop() {
      font-size: 20px;
      line-height: 1.2;
    }

    &-box {
      overflow: hidden;
      padding-right: 10px;
    }

    &,
    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  &-avatar {
    object-fit: cover;
    object-position: 50% 50%;
    @include min-desktop() {
      width: 46px;
      height: 46px;
      font-size: 44px;
      margin-right: 5px;
    }
    @include max-desktop() {
      width: 60px;
      height: 60px;
      font-size: 60px;
      margin-right: 20px;
    }

    &.current {
      border: 2px solid $azure;
    }
  }

  &-game {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 8px;

    .game-tag {
      margin-right: 2px;
      font-size: 11px;
    }

    .game-nick {
      color: rgba(white, 0.3);
    }
  }

  &-more {
    padding: 30px 20px;
    border-top: 1px solid $popover-bg;
    display: none;

    &.open {
      @include min-desktop() {
        display: flex;
        align-items: flex-start;
      }
      @include max-desktop() {
        display: block;
      }
    }

    &-btn {
      @include min-desktop() {
        width: 24px;
        flex-shrink: 0;
        transition: 0s;
        background-color: $popover-bg;
        color: rgba(white, 0.3);
        order: 5;
      }

      @include max-desktop() {
        color: $azure;
        width: 100%;
        margin-top: 20px;
        &,
        &:focus {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .collapse {
        display: none;
        padding-right: 0.5em;
      }

      .expand {
        padding-right: 0.5em;
        @include min-desktop() {
          display: none;
        }
      }

      &.up {
        ::v-deep .icon {
          transform: rotate(180deg);
        }

        @include max-desktop() {
          .collapse {
            display: block;
          }
          .expand {
            display: none;
          }
        }
      }
    }

    .info-text {
      @include max-desktop() {
        margin-top: 10px;
      }
    }

    .link {
      margin-left: auto;
      font-size: 15px;
      flex-shrink: 0;
      @include max-desktop() {
        margin-top: 20px;
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.info {
  &-title {
    color: rgba(white, 0.3);
    font-size: 13px;
    margin-right: 16px;
    @include max-desktop() {
      flex-shrink: 0;
      min-width: 65px;
    }
  }

  &-text {
    font-size: 13px;
    color: white;
    padding-right: 12px;

    .notfound {
      color: rgba(white, 0.5);
    }

    .button {
      line-height: 1;
      color: white;
      @include min-desktop() {
        font-size: 16px;
        & + .button {
          margin-left: 8px;
        }
      }
      @include max-desktop() {
        font-size: 21px;
        & + .button {
          margin-left: 20px;
        }
      }
    }
  }
}

.nomatches {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 12px;

  .icon {
    width: 30px;
    height: 28px;
    flex-shrink: 0;
    margin-right: 20px;
  }
}

.contact {
  border: 1px solid;
  padding: 1px;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  border-color: rgba(white, 0);

  &:hover {
    border-color: $azure;
    background-color: $charcoal-grey;
  }

  .icon {
    font-size: 14px;
  }
}

.matches-progressbar {
  font-size: 12px;
}
</style>
