<template>
  <div class="remove">
    <div class="remove-text">
      {{ $t('jobfair.del_confirm') }}
    </div>

    <div class="form-footer">
      <pvp-btn variant="secondary" @click="$emit('send', 'CLOSE')">
        {{ $t('global.cancel') }}
      </pvp-btn>
      <pvp-btn @click="deleteProfile">
        {{ $t('global.delete') }}
      </pvp-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobfairDeleteProfile',
  props: {
    cardId: {
      type: Number,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
  },

  methods: {
    deleteProfile() {
      api
        .post('/declaration/delete', {
          type: this.cardType,
          id: this.cardId,
        })
        .then(() => {
          this.$emit('send', 'CLOSE');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-text {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
</style>
