import { render, staticRenderFns } from "./ProfileMatchProgress.vue?vue&type=template&id=21aaac7e"
import script from "./ProfileMatchProgress.vue?vue&type=script&lang=js"
export * from "./ProfileMatchProgress.vue?vue&type=script&lang=js"
import style0 from "./ProfileMatchProgress.vue?vue&type=style&index=0&id=21aaac7e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports