<template>
  <div class="age-range">
    <div class="age-box">
      <div class="age-align">
        {{ $t('global.from') }}
        <BaseInput
          :value="value[0]"
          type="number"
          @change="(e) => onInput(e, 0)"
        />
      </div>
      <div class="age-align">
        {{ $t('global.to') }}
        <BaseInput
          :value="value[1]"
          type="number"
          @change="(e) => onInput(e, 1)"
        />
      </div>
    </div>
    <InputRange
      :value="value"
      tooltip="none"
      :min="min"
      :max="max"
      @change="onRangeChange"
    />
  </div>
</template>

<script>
import InputRange from '@components/BaseComponents/Form/InputRange.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';

export default {
  name: 'AgeRange',
  components: {
    InputRange,
    BaseInput,
  },
  props: {
    value: {
      type: Array,
      default: () => [0, 100],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    onInput(event, pos) {
      let age = [...this.value];
      const value = ((num) => {
        if (num < this.min) {
          return this.min;
        }
        if (num > this.max) {
          return this.max;
        }
        return +num;
      })(+event.target.value);
      age[pos] = value;
      age = age.sort((a, b) => {
        if (a === b) {
          return 0;
        }
        return a > b ? 1 : -1;
      });
      this.onRangeChange(age);
    },
    onRangeChange(data) {
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.age-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  .label {
    margin: 0;
    width: 60px;

    ::v-deep input {
      text-align: center;
    }
  }

  .age-align {
    display: flex;
    align-items: center;
    font-size: em(14px);
    color: rgba(white, 0.5);

    .label {
      margin-left: 0.5em;
    }
  }
}
</style>
