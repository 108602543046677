<template>
  <loader v-if="isLoading" />
  <div v-else class="acc-type">
    <BaseRadio
      v-model="type"
      :disabled="!abilities.canCreateResume"
      value="resume"
    >
      {{ $t('jobfair.search_team_title') }}
      <div class="desc">
        <template v-if="abilities.canCreateResume">
          {{ $t('jobfair.search_team_description') }}
        </template>
        <i18n v-else path="jobfair.exist_player">
          <BaseLink
            slot="profile"
            :to="{
              name: 'profile-jobfair',
              params: { hash: profile.hash },
            }"
          >
            {{ $t('jobfair.exist_profile') }}
          </BaseLink>
        </i18n>
      </div>
    </BaseRadio>

    <BaseRadio
      v-model="type"
      :disabled="!abilities.canCreateVacancy"
      value="vacancy"
    >
      {{ $t('jobfair.search_player_title') }}
      <div class="desc">
        <template v-if="abilities.canCreateVacancy">
          {{ $t('jobfair.search_player_description') }}
        </template>
        <i18n v-else path="jobfair.exist_team">
          <BaseLink
            slot="profile"
            :to="{
              name: 'profile-jobfair',
              params: { hash: profile.hash },
            }"
          >
            {{ $t('jobfair.exist_profile') }}
          </BaseLink>
        </i18n>
      </div>
    </BaseRadio>

    <div class="form-footer">
      <pvp-btn variant="secondary" @click="back">{{
        $t('global.back')
      }}</pvp-btn>
      <pvp-btn :disabled="!type" @click="next">
        {{ $t('global.next') }}</pvp-btn
      >
    </div>
  </div>
</template>

<script>
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'JobfairCreationTypeSelect',
  components: {
    BaseRadio,
    BaseLink,
  },
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    type: null,
    abilities: {},
  }),
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('teams', ['getPlayerTeamsByGameId']),
    ...mapGetters('profile', ['getCurrentProfileHash']),
  },
  created() {
    Promise.all([
      api.get(`/declaration/declarer/game/${this.gameId}/ability`),
      this.fetchUserProfile({
        hash: this.getCurrentProfileHash,
        complex: true,
      }),
    ])
      .then(([abilities]) => {
        this.abilities = abilities;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('users', ['fetchUserProfile']),
    next() {
      this.$emit('send', 'NEXT', {
        cardType: this.type,
        hasTeam:
          this.getPlayerTeamsByGameId({
            gameId: this.gameId,
          })?.length > 0,
      });
    },

    back() {
      this.$emit('send', 'BACK');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-radio {
  flex-direction: column;
  margin-top: 24px;
}

.desc {
  margin-top: 10px;
  color: rgba(white, 0.4);
  font-size: 15px;
  line-height: 1.2;
}
</style>
