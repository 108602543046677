<template>
  <pvp-modal
    :value="state.context.isOpen"
    :width="580"
    @input="sendEvent('CLOSE')"
  >
    <template #modal-title>{{ container.title }}</template>

    <component
      :is="container.is"
      v-if="container.is"
      v-bind="container.props"
      @send="sendEvent"
    />
  </pvp-modal>
</template>

<script>
import { useMachine } from 'xstate-vue2';
import { jobfairCardModal } from '@src/machines/jobfairCardModal.js';
import JobfairCreationGameSelect from '@components/Jobfair/creation/JobfairCreationGameSelect.vue';
import JobfairCreationTypeSelect from '@components/Jobfair/creation/JobfairCreationTypeSelect.vue';
import JobfairSettingsForm from '@components/Jobfair/JobfairSettingsForm.vue';
import JobfairCreationSuccess from '@components/Jobfair/creation/JobfairCreationSuccess.vue';
import JobfairDeleteProfile from '@components/Jobfair/JobfairDeleteProfile.vue';
import NewTeamForm from '@components/TeamComponents/TeamManagement/NewTeamForm.vue';

export default {
  name: 'JobfairSettingsModal',

  components: {
    JobfairSettingsForm,
    JobfairCreationGameSelect,
    JobfairCreationTypeSelect,
    JobfairCreationSuccess,
    JobfairDeleteProfile,
    NewTeamForm,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    settingsOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { state, send } = useMachine(jobfairCardModal);
    return {
      state,
      send,
    };
  },

  data() {
    return {};
  },

  computed: {
    container() {
      const { gameId, cardType, teamName, cardId } =
        this.state.context;

      switch (this.state.value) {
        case 'gameSelect':
          return {
            title: this.$t('games.chooseSelector'),
            is: 'JobfairCreationGameSelect',
            props: { gameId },
          };
        case 'cardTypeSelect':
          return {
            title: this.$t('jobfair.select_type'),
            is: 'JobfairCreationTypeSelect',
            props: { gameId },
          };
        case 'teamCreation':
          return {
            title: this.$t('teams.create_title'),
            is: 'NewTeamForm',
            props: { defaultGameId: gameId },
          };
        case 'cardSettings':
          return {
            title: this.settingsOptions.cardId
              ? this.$t('jobfair.profile_edit')
              : this.$t('jobfair.profile_creation'),
            is: 'JobfairSettingsForm',
            props: { gameId, cardType, cardId },
          };
        case 'deleteConfirmation':
          return {
            title: this.$t('jobfair.del_title'),
            is: 'JobfairDeleteProfile',
            props: { cardId, cardType },
          };
        case 'creationSucceed':
          return {
            title: this.$t('jobfair.profile_added'),
            is: 'JobfairCreationSuccess',
            props: { gameId, teamName },
          };
        default:
          return {};
      }
    },
  },

  watch: {
    value(isOpen) {
      if (isOpen) {
        return this.sendEvent('OPEN', this.settingsOptions);
      }
    },
  },

  methods: {
    sendEvent(event, payload) {
      this.send(event, payload);
      const { isOpen, needUpdate } = this.state.context;
      if (isOpen === false) {
        this.$emit('input', false);
      }
      if (needUpdate) {
        this.$emit('success');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-footer {
  text-align: center;
  border-top: 2px solid rgba(white, 0.1);
  margin: 20px -12px 0;
  padding-top: 16px;
  padding-bottom: 16px;

  @include min-tablet() {
    margin-left: -30px;
    margin-right: -30px;
  }

  .button {
    width: 158px;
    @include min-tablet() {
      margin: 10px 20px;
    }
    @include max-tablet() {
      margin: 5px;
    }
  }
}
</style>
