<template>
  <div class="games">
    <div class="games-list">
      <pvp-btn
        v-for="game in games"
        :key="game.id"
        variant="secondary"
        :class="{ active: selectedGameId === game.id }"
        @click="toggleGame(game.id)"
      >
        <game-tag
          :code="game.code"
          :display-name="false"
          class="game-icon"
        />
        <span class="game-title">{{ game.shortName }}</span>
      </pvp-btn>
    </div>
    <div class="form-footer">
      <Button
        v-if="hasAccount || !selectedGameId"
        size="regular"
        type="primary"
        :text="$t('profile.terms_continue')"
        :disabled="!hasAccount"
        @click="setGame"
      />
      <game-account-linker
        v-else-if="selectedGameId"
        :id="selectedGameId"
      />
    </div>
  </div>
</template>

<script>
import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'JobfairCreationGameSelect',
  components: { Button, GameAccountLinker },
  props: {
    gameId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    selectedGameId: null,
  }),
  computed: {
    ...mapGetters('application', ['getGames', 'getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    games() {
      return this.getGames.filter(
        ({ use_in_job_fair: isJobfair }) => isJobfair,
      );
    },

    hasAccount() {
      return (
        this.getLinkedGameAccount(this.selectedGameId)?.hasAccount ||
        false
      );
    },
  },

  created() {
    this.selectedGameId = this.getGame(this.gameId || 1).id || {};
  },

  methods: {
    toggleGame(id) {
      this.selectedGameId = this.selectedGameId === id ? null : id;
    },
    setGame() {
      this.$emit('send', 'NEXT', { gameId: this.selectedGameId });
    },
  },
};
</script>

<style lang="scss" scoped>
.games {
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $dark;

    @include min-tablet() {
      border-radius: 6px;
      padding: 42px;
    }
    @include max-tablet() {
      padding: 12px;
    }

    .button {
      flex-direction: column;
      width: 106px;
      height: 98px;
      margin: 8px;
      background-color: rgba(black, 0.1);

      &:focus.active,
      &.active {
        box-shadow: 0 0 0 2px $azure;
        background-color: #595a61;
      }
    }
  }
}

.game {
  &-icon {
    font-size: 24px;
    width: 42px;
    height: 42px;
  }

  &-title {
    display: block;
    text-align: center;
    color: rgba(white, 0.5);
    margin-top: 5px;
    font-size: 15px;
    line-height: 24px;
  }
}
</style>
