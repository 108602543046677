<template>
  <div class="matches-progressbar">
    <div class="matches-stats-list">
      <div class="stats-list-item wins">
        <div class="key">{{ $t('matches.results_wins') }}</div>
        <div class="value">
          {{ stats.wonMatchesCount }} ({{
            stats.wonMatchesPercentage
          }}%)
        </div>
      </div>
      <div class="stats-list-item loses">
        <div class="key">{{ $t('matches.results_defeats') }}</div>
        <div class="value">
          {{ stats.loseMatchesCount }} ({{
            stats.loseMatchesPercentage
          }}%)
        </div>
      </div>
      <div class="stats-list-item withdraw">
        <div class="key">{{ $t('matches.results_draw') }}</div>
        <div class="value">{{ stats.drawMatchesCount }}</div>
      </div>
    </div>
    <div class="matches-stats-bar">
      <div
        class="filler-bar"
        :style="{ width: `${stats.wonMatchesPercentage}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileMatchProgress',
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.matches-stats-list {
  display: flex;

  .stats-list-item {
    .key {
      font-size: 13px;
      color: rgba(white, 0.5);
    }

    .value {
      font-size: inherit;
      line-height: 1.6;
      font-weight: 600;
    }

    &.wins {
      .value {
        color: var(--additional-color-green);
      }
    }

    &.loses {
      .value {
        color: var(--additional-color-red);
      }
    }

    &.withdraw {
      .value {
        color: rgba(white, 0.5);
      }
    }
  }

  .stats-list-item + .stats-list-item {
    margin-left: 20px;
  }
}

.matches-stats-bar {
  margin-top: 0.6em;
  height: 6px;
  border-radius: 3px;
  background-color: var(--additional-color-red);
  display: flex;
  overflow: hidden;

  .filler-bar {
    height: 100%;
    background-color: var(--additional-color-green);
  }
}
</style>
